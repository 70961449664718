<template>
  <CustomDrawer
    ref="el"
    :value="isShowCartFloorDrawer"
    :iconColor="isLoading ? '#f2f2f2' : '#fff'"
    :styleConfig="{
      top: 'auto',
      height: '70%'
    }"
    :style="itemStyle"
    attach=".j-cart-checkout"
    @close="toggleVisible(isLoading)"
    @afterEnter="handleScroll"
  >
    <CartFloorSkeleton v-if="isLoading" />
    <CartFloorWrapper v-else>
      <template #header>
        <CartFloorHeader :title="popupCartLure.title" />
      </template>
      <template #content>
        <CartFloorGroup
          v-for="group in cartLureList"
          :key="group.type"
        >
          <template #title>
            <SCheckbox
              :value="getChecked(group.cartIds)"
              :margin-r="20 / 75 + 'rem'"
              @change="handleChecked(group.cartIds, $event.checked, 'section')"
              @click.native="handleCheckAllReport(group)"
            />
            <CartFloorGroupTitle
              :iconUrl="group.icon"
              :groupCount="group.cartIds.length"
              :groupName="group.subTitle"
            />
          </template>
          <template #item>
            <div
              v-for="item in group.carts"
              :key="item.id"
              :class="['cart-floor__item', `j-cart-item-${item.id}`]"
            >
              <div class="cart-floor__item-checkbox">
                <SCheckbox
                  :label="item.id"
                  :value="getChecked(item.id)"
                  @change="handleChecked(item.id, $event.checked, 'item')"
                />
              </div>
              <div
                class="cart-floor__item-content"
              >
                <BMainCartItem
                  :key="item.id"
                  :data="item"
                  :language="language"
                  :constant="constant"
                  :use="use"
                  :config="config"
                  @expose="handleItemExpose(item)"
                />
              </div>
            </div>
          </template>
        </CartFloorGroup>
      </template>
    </CartFloorWrapper>
  </CustomDrawer>
</template>

<script setup>
import { computed, watch, onBeforeMount, onMounted, ref, nextTick } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { getRouter } from 'public/src/pages/cart_v2/components/cartFloorDrawer/hooks/useCartFloorDrawer'
import CartFloorSkeleton from './components/CartFloorSkeleton.vue'
import CustomDrawer from 'public/src/pages/cart_v2/components/common/CustomDrawer.vue'
import CartFloorWrapper from './components/CartFloorWrapper.vue'
import CartFloorHeader from './components/CartFloorHeader.vue'
import CartFloorGroup from './components/CartFloorGroup.vue'
import CartFloorGroupTitle from './components/CartFloorGroupTitle.vue'
import { BMainCartItem, getConstant } from '@shein-aidc/bs-cart-item-mobile-vue2'
import { useItemEvents } from 'public/src/pages/cart_v2/hooks/item/index.js'
import { scrollToItem } from 'public/src/pages/cart_v2/utils/index.js'
import { preloadImgs } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { FLASH_SALE, ALMOST_SOLD_OUT, FREE_SHIPPING, DISCOUNT, PRICE_REDUCTION, INCENTIVE_POINT_MAP } from 'public/src/pages/common/cartFloor/utils/constant.js'
import useChecked from 'public/src/pages/cart_v2/hooks/useChecked.js'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus.js'
import { expose_goods_list, getSaGoodsList } from 'public/src/pages/cart_v2/analysis/item/index.js'

// hooks
const { discountLimitTagEvent, discountNormalTagEvent, estimatedPriceEvent, goodsQtyEvent, goodsSaleAttrEvent, behaviorLabelEvent } = useItemEvents()
const { getChecked } = useChecked()
const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const { state, commit, getters } = useStore()

// data
const el = ref(null)
const updateState = (payload) => commit('cart_v2/updateState', payload)
const constant = ref(getConstant())
const use = ref([
  'GoodsImg',
  'GoodsDiscountFlag',
  'GoodsBelt',
  'GoodsTitle',
  'GoodsTitleIcon',
  'GoodsSeriesFlag',
  'GoodsPrice',
  'GoodsAlert',
  'GoodsSaleAttr',
  'ReducePriceHint',
  'GoodsQty',
  'GoodsStore',
  'BehaviorLabel',
  'QuickShipTag',
  'SFSTag',
  'FreeShippingTag',
  'EvoluTag',
  'VoucherTag',
  'PromotionTag',
  'DiscountNormalTag',
  'DiscountLimitTag',
  'EstimatedPrice',
])
const labelId = computed(() => state.filterState?.selectFilterLabel?.labelId ?? '')
const cartAbtInfo = computed(() => state.cartAbtInfo)
const language = computed(() => state.language)
const isShowCartFloorDrawer = computed(() => state.isShowCartFloorDrawer)
const cartItemMap = computed(() => state.cartItemMap ?? {})
const popupCartLure = computed(() => state.popupCartLure)
const cartLureList = computed(() => popupCartLure.value?.cartLureList?.map(item => ({
  ...item,
  carts: item.cartIds?.map(id => cartItemMap.value[id])
})) ?? [])
const cartIds = computed(() => popupCartLure.value?.cartLureList?.flatMap(item => item.cartIds) ?? [])
const isLoading = computed(() => !popupCartLure.value)
const isNewCartrowVision = computed(() => getters['cart_v2/isNewCartrowVision'])
const itemStyle = computed(() => ({
  '--item-gap': isNewCartrowVision.value ? '0.2133rem' : '0.2667rem',
  '--template-left-width': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  '--template-left-height': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  '--checkbox-margin-right': isNewCartrowVision.value ? '0.2133rem' : '0.26666667rem',
}))
const config = ref({
  GoodsDiscountFlag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  BehaviorLabel: {
    position: !isNewCartrowVision.value ? 'top' : 'bottom',
    events: {
      onLabelExpose(itemOpts) {
        behaviorLabelEvent.onLabelExpose({ itemOpts }) 
      }
    }
  },
  QuickShipTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  FreeShippingTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  EvoluTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  VoucherTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  DiscountNormalTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    events: {
      onClick(itemOpts) {
        const analysis = {
          click_discount_details(data) {
            data.scene = 'cart_floor_benefit_pop'
            data.result_order = getIndex(itemOpts.data.value.id) + 1
            return data
          }
        }
        discountNormalTagEvent.onClick({ itemOpts, analysis })
      }
    }
  },
  DiscountLimitTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    events: {
      onClick(itemOpts) {
        const analysis = {
          click_discount_details(data) {
            data.scene = 'cart_floor_benefit_pop'
            data.result_order = getIndex(itemOpts.data.value.id) + 1
            return data
          }
        }
        discountLimitTagEvent.onClick({ itemOpts, analysis })
      }
    }
  },
  SFSTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  GoodsPrice: {
    props() {
      return {
        pricePopoverConfig: {
          listenTarget: '.j-incentive-list-content',
        }
      }
    }
  },
  GoodsStore: {
    props() {
      return {
        isClick: false,
      }
    },
  },
  GoodsImg: {
    props() {
      return {
        isClick: false,
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        isClick: false,
      }
    },
  },
  GoodsSaleAttr: {
    events: {
      onClick(itemOpts) {
        const analysis = {
          expose_update_popup(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          }
        }
        const options = {
          index: getIndex(itemOpts.data.value.id) + 1,
        }
        goodsSaleAttrEvent.onClick({ itemOpts, options, analysis })
      }
    }
  },
  PromotionTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      return {
        isClick: false,
      }
    },
  },
  EstimatedPrice: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    events: {
      onClick(itemOpts) {
        const analysis = {
          click_estimated_price(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          }
        }
        estimatedPriceEvent.onClick({ itemOpts, analysis })
      },
      onExposeChange(itemOpts) {
        const analysis = {
          expose_estimated_price(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          }
        }
        estimatedPriceEvent.onExposeChange({ itemOpts, analysis })
      }
    }
  },
  GoodsQty: {
    events: {
      onMinus(itemOpts) {
        goodsQtyEvent.onMinus({ itemOpts })
      },
      onPlus(itemOpts) {
        goodsQtyEvent.onPlus({ itemOpts })
      },
      onDelete(itemOpts) {
        const options = {}
        const analysis = {
          click_goods_delete(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          },
          expose_delete_tips(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          }
        }
        goodsQtyEvent.onDelete({ itemOpts, options, analysis })
      },
      onClickInput(itemOpts) {
        const analysis = {
          click_cart_quantity_edit(data) {
            data.scene = 'cart_floor_benefit_pop'
            data.position = 'popup'
            return data
          }
        }
        goodsQtyEvent.onClickInput({ itemOpts, analysis })
      },
      onChange(itemOpts) {
        const analysis = {
          click_goods_add_qty(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          },
          click_goods_sub_qty(data) {
            data.scene = 'cart_floor_benefit_pop'
            return data
          }
        }
        goodsQtyEvent.onChange({ itemOpts, analysis })
      }, 
    }
  }
})

// watcher
watch(() => isShowCartFloorDrawer.value, (val) => {
  if (!val) {
    const { router, route } = getRouter()
    router?.replace?.({
      ...route,
      query: {},
    })
  }
})

// methods
const getIndex = (id) => {
  return cartIds.value.findIndex(cartId => cartId === id)
}
const toggleVisible = (visible) => {
  updateState({
    key: 'isShowCartFloorDrawer',
    value: visible
  })
}
const handleVisible = () => {
  watch(() => popupCartLure.value, (popupCartLure) => {
    if (!popupCartLure) return
    if (popupCartLure.cartLureList?.length > 1 || popupCartLure.cartLureList?.[0]?.cartIds?.length > 1) {
      toggleVisible(true)
    } else if (popupCartLure.cartLureList?.length === 1 && popupCartLure.cartLureList[0]?.cartIds?.length === 1) {
      updateState({
        key: 'notShowCartFloorDrawer',
        value: true,
      })
    }
  }, {
    immediate: true,
    deep: true,
  })
}
const handleChecked = (item, isChecked, type) => {
  const cartIdList = Array.isArray(item) ? item : [item]
  const options = {
    cartIdList,
    operationType: isChecked ? 1 : 2,
    type,
  }
  const analysis = {
    click_select_someproduct(data) {
      return {
        ...data,
        is_select: isChecked ? 0 : 1,
        scene: 'cart_floor_benefit_pop',
        position: 'popup',
      }
    }
  }
  modifyCartCheckStatus(options, analysis)
}
const handleScroll = () => {
  nextTick(() => {
    scrollToItem({
      container: document.querySelector('.j-cart-floor__scroll'),
      cartItemId: getRouter().route?.query?.to,
    })
  })
}
const handleItemExpose = (item) => {
  expose_goods_list(item, (defaultData) => {
    const data = {
      ...defaultData,
      scene: 'cart_floor_benefit_pop',
      label_id: labelId.value,
      goods_list: getSaGoodsList(item, { index: getIndex(item.id), cartAbtInfo: cartAbtInfo.value, scene: 'cart_floor_benefit_pop', el: el.value?.querySelector?.(`.j-cart-item-${item.id}`) }),
    }
    return data
  })
}
const handleCheckAllReport = (data) => {
  const typeMap = {
    [INCENTIVE_POINT_MAP[ALMOST_SOLD_OUT]]: 'oos',
    [INCENTIVE_POINT_MAP[PRICE_REDUCTION]]: 'mark_down',
    [INCENTIVE_POINT_MAP[FLASH_SALE]]: 'flash_sale',
    [INCENTIVE_POINT_MAP[DISCOUNT]]: 'discount',
    [INCENTIVE_POINT_MAP[FREE_SHIPPING]]: 'freeshipping'
  }
  daEventCenter.triggerNotice({ 
    daId: '1-8-3-62',
    extraData: {
      scene: 'cart_floor_benefit_pop',
      cart_floor_type: typeMap[data.type] || 'other',
      is_select: getChecked(data.cartIds) ? 0 : 1
    }
  })
}
onBeforeMount(() => {
  preloadImgs({
    imgs: [
      'https://img.ltwebstatic.com/images3_ccc/2024/09/02/25/1725271407aa5311592e8413d6658acfb49289a640.webp'
    ]
  })
})
onMounted(() => {
  updateState({
    key: 'loadingShow',
    value: false,
  })
  handleVisible()
})
</script>

<style lang="less" scoped>
.cart-floor {
  &__item {
    display: flex;
    padding-bottom: 10/37.5rem;
    padding-left: var(--item-gap);
    padding-right: var(--item-gap);
  }
  &__item + &__item {
    padding-top: 10/37.5rem;
  }
  &__item-checkbox {
    display: flex;
    align-items: center;
    height: var(--template-left-height, 1.973rem);
    flex-shrink: 0;
    .S-checkbox {
      /* stylelint-disable-next-line declaration-no-important */
      margin-right: var(--checkbox-margin-right, 0.2667rem) !important;
    }
  }
  &__item-content {
    flex: 1;
    min-width: 0;
  }
}
</style>
