/**
 * 闪购
 */
export const FLASH_SALE = 'FLASH_SALE'

/**
 * 即将售罄
 */
export const ALMOST_SOLD_OUT = 'ALMOST_SOLD_OUT'

/**
 * 加车后降价
 */
export const PRICE_REDUCTION = 'PRICE_REDUCTION'

/**
 * 购物车勾选商品折扣
 */
export const DISCOUNT = 'DISCOUNT'

/**
 * 免邮
 */
export const FREE_SHIPPING = 'FREE_SHIPPING'

/**
 * 默认展示利诱点
 */
export const DEFAULT_INCENTIVE_POINT = [
  FLASH_SALE,
  ALMOST_SOLD_OUT,
  PRICE_REDUCTION,
  DISCOUNT,
  FREE_SHIPPING,
]

/**
 * 利诱点对应类型
 */
export const INCENTIVE_POINT_MAP = {
  [FLASH_SALE]: 4,
  [ALMOST_SOLD_OUT]: 1,
  [PRICE_REDUCTION]: 2,
  [DISCOUNT]: 9,
  [FREE_SHIPPING]: 10,
}

/**
 * 购物车楼层接口数据缓存 KEY
 */
export const CART_FLOOR_API_DATA_CACHE_KEY = Symbol('CART_FLOOR_API_DATA_CACHE_KEY')

/**
 * 购物车楼层监听购物车数据变化状态
 */
export const CART_FLOOR_CHANGE_CART_LISTEN = Symbol('CART_FLOOR_CHANGE_CART_LISTEN')
